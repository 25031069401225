/**
 * This file contains all the events we fire for Product analytics purposes to Segment
 * and the functions to fire these events from throughout the app.
 *
 * Events are separated into 2 main categories:
 *    1. User-performed actions - e.g. button clicks, slider mouseup release etc.
 *    2. Page/View/UI Element impressions - e.g. a page is visited, a modal is opened etc.
 */

import { useCallback, useEffect, useMemo, useRef } from "react";

import { useMaybeUser } from "../auth/useUser";
import { ConsoleLog } from "../helpers";

export const SegmentTrackEventGenerator = {
  MCVendorSummaryEditDetailsEdited: (fieldName: string): string =>
    `mc-vendor-summary__edit-details__${fieldName}__edited`,
  MCVendorSummaryEditCustomFieldsEdited: (fieldName: string): string =>
    `mc--vendor-summary__edit-custom-fields__${fieldName}__edited`,
};

/**
 * ISegmentTrackEvent describes the list of all user-performed actions.
 *
 * The naming convention for the event names that's sent to Segment is a combination of:
 *  - Flow: where in the app flow this event is fired from e.g. 'trading-flow'
 *  - View: can be (a) an individual page (2) a modal (3) a list view etc.
 *  - UI Element: button, slider, etc. e.g. 'submit-payout-button'
 *  - Action: an user-initiated action e.g. click, drag etc.
 *
 * The formula for the final ISegmentTrackEvent name is: flow + view + ui element + action
 */
export enum ISegmentTrackEvent {
  // Connect API
  ConnectAPIKeyManagementAllIPsAllowedClicked = "connect-api__key-management-all-ips-allowed__clicked",
  ConnectAPIKeyManagementCreateButtonClicked = "connect-api__key-management-create-button__clicked",
  ConnectAPIKeyManagementDeleteButtonClicked = "connect-api__key-management-delete-button__clicked",
  ConnectAPIKeyManagementIPAllowListAdded = "connect-api__key-management-ip-allow-list__added",
  ConnectAPIKeyManagementIPAllowListClicked = "connect-api__key-management-ip-allow-list__clicked",
  ConnectAPIKeyManagementIPAllowListRemoved = "connect-api__key-management-ip-allow-list__removed",

  // Legacy one-off event
  EmailProvided = "email_provided_event",

  // Finance flow
  FinanceOverviewPageConnectWithFinanceTeamButtonClick = "finance-flow__overview-page__connect-with-finance-team-button__clicked",
  FinanceStatementsPageConnectWithFinanceTeamButtonClick = "finance-flow__statements-page__connect-with-finance-team-button__clicked",

  // Help Widget
  HelpWidgetButtonClicked = "help-widget__button__clicked",
  HelpWidgetCallUsClicked = "help-widget__call-us__clicked",
  HelpWidgetOpenIntercomClicked = "help-widget__open-intercom__clicked",
  HelpWidgetSendEmailClicked = "help-widget__send-email__clicked",
  HelpWidgetViewHelpDocsClicked = "help-widget__view-help-docs__clicked",

  // Investor page events
  InvestorContractsConnectWithFinanceTeamButtonClicked = "investor-flow__contracts-page__connect-with-finance-team-button__clicked",
  InvestorOrdersConnectWithFinanceTeamButtonClicked = "investor-flow__orders-page__connect-with-finance-team-button__clicked",
  InvestorSettingsAccountPageUpdateSettingsButtonClick = "investor-flow__settings-account-page__update-settings-button__clicked",
  InvestorTransfersConnectWithFinanceTeamButtonClicked = "investor-flow__transfers-page__connect-with-finance-team-button__clicked",

  // Mission Control
  MCDemosCustomDemoCreateDemoClicked = "mc-demos__custom_demo__create-demo__clicked",

  MCDemosQuickDemo21mArrGbpClicked = "mc-demos__quick-demo__21m-arr-gbp__clicked",
  MCDemosQuickDemo21mArrUsdClicked = "mc-demos__quick-demo__21m-arr-usd__clicked",
  MCDemosQuickDemo2mArrGbpClicked = "mc-demos__quick-demo__2m-arr-gbp__clicked",
  MCDemosQuickDemo2mArrUsdClicked = "mc-demos__quick-demo__2m-arr-usd__clicked",
  MCDemosQuickDemo36moQuarterlyUsdClicked = "mc-demos__quick-demo__36mo-quarterly-usd__clicked",
  MCDemosQuickDemo50kStreamsLoadTestClicked = "mc-demos__quick-demo__50k-streams-load-test__clicked",
  MCDemosQuickDemoInvestorClicked = "mc-demos__quick-demo__investor__clicked",
  MCDemosQuickDemoOnboardingFlowClicked = "mc-demos__quick-demo__onboarding-flow__clicked",
  MCHeaderCommandKListItemClicked = "mc-header__command-k__list-item__clicked",

  MCHeaderCommandKVendorItemClicked = "mc-header__command-k__vendor-item__clicked",
  MCHomeListPreviewsHasntTradedIn30DaysClicked = "mc-home__list-previews__hasnt-traded-in-30-days__clicked",
  MCHomeListPreviewsLeastEngagedCustomersClicked = "mc-home__list-previews__least-engaged-customers__clicked",
  MCHomeListPreviewsLikelyToTradeClicked = "mc-home__list-previews__likely-to-trade__clicked",
  MCHomeListPreviewsOnboardingOver24HoursClicked = "mc-home__list-previews__onboarding-over-24-hours__clicked",
  MCMyListsButtonsDuplicateClicked = "mc-my-lists__buttons__duplicate__clicked",
  MCMyListsButtonsEditClicked = "mc-my-lists__buttons__edit__clicked",
  MCMyListsButtonsSaveClicked = "mc-my-lists__buttons__save__clicked",
  MCSalesButtonsExportVendorsClicked = "mc-sales__buttons__export-vendors__clicked",
  MCVendorDataSourcesDeleteConfirmClicked = "mc-vendor-data-sources__delete__confirm__clicked",
  MCVendorDataSourcesUploadRevenueCsvUploadClicked = "mc-vendor-data-sources__upload-revenue-csv__upload__clicked",
  MCVendorMessageFeedSendMessageMessageBoxSubmitted = "mc-vendor-message-feed__send-message__message-box__submitted",
  MCVendorSettingsUpdateDetailsAccountOwnerUpdated = "mc-vendor-settings__update-details__account-owner__updated",
  MCVendorSettingsUpdateDetailsCompanyUpdated = "mc-vendor-settings__update-details__company__updated",
  MCVendorSummaryVendorImpersonationButtonClicked = "mc-vendor-summary__vendor-impersonation__button__clicked",
  MCVendorUsersInviteUserFormSubmitted = "mc-vendor-users__invite-user__form__submitted",
  MCVendorUsersUpdateUserRoleSelectSelected = "mc-vendor-users__update-user__role-select__selected",

  // Legacy one-off event
  MissingIntegration = "missing_integration_event",

  // Notifications
  NotificationsBadgeClicked = "notifications-badge__clicked",
  // Settings flow
  SettingsAddAccountingButtonClick = "settings-flow__integrations-page__add-accounting-button__clicked",
  SettingsAddAccountingDataModalAccountingDetailsModalBackButtonClick = "settings-flow__add-accounting-data-modal__accounting-details-modal__back-button__clicked",
  SettingsAddAccountingDataModalAccountingDetailsModalClose = "settings-flow__add-accounting-data-modal__accounting-details-modal__closed-out",
  SettingsAddAccountingDataModalAccountingDetailsModalInstructionsButtonClick = "settings-flow__add-accounting-data-modal__accounting-details-modal__instructions-button__clicked",
  SettingsAddAccountingDataModalAccountingDetailsModalSubmitButtonClick = "settings-flow__add-accounting-data-modal__accounting-details-modal__submit-button__clicked",
  SettingsAddAccountingDataModalCSVUploaderModalBackButtonClicked = "settings-flow__add-accounting-data-modal__csv-uploader-modal__back-button__clicked",
  SettingsAddAccountingDataModalCSVUploaderModalClosedOut = "settings-flow__add-accounting-data-modal__csv-uploader-modal__closed-out",
  SettingsAddAccountingDataModalCSVUploaderModalFileUploaded = "settings-flow__add-accounting-data-modal__csv-uploader-modal__file__uploaded",
  SettingsAddAccountingDataModalCSVUploaderModalTemplateLinkClick = "settings-flow__add-accounting-data-modal__csv-uploader-modal__template-link__clicked",
  SettingsAddAccountingDataModalCSVUploaderModalUploadButtonClick = "settings-flow__add-accounting-data-modal__csv-uploader-modal__upload-button__clicked",
  SettingsAddAccountingDataModalDataSourceClick = "settings-flow__add-accounting-data-modal__accounting-data-source__clicked",
  SettingsAddBankingButtonClick = "settings-flow__integrations-page__add-banking-button__clicked",
  SettingsAddBillingButtonClick = "settings-flow__integrations-page_add-billing-button__clicked",
  SettingsBillingManagerDetailsModalBackButtonClick = "settings-flow__billing-manager-details-modal__back-button__clicked",
  SettingsBillingManagerDetailsModalClose = "settings-flow__billing-manager-details-modal__closed-out",
  SettingsBillingManagerDetailsModalInstructionsButtonClick = "settings-flow__billing-manager-details-modal__instructions-button__clicked",
  SettingsBillingManagerDetailsModalSubmitButtonClick = "settings-flow__billing-manager-details-modal__submit-button__clicked",
  SettingsBillingManagerInModalClick = "settings-flow__billing-manager-in-modal__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalBackButtonClicked = "settings-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__back-button__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalClosedOut = "settings-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__closed-out",
  SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalContactSupportLinkClick = "settings-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__contact-support-link__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalContinueButtonClick = "settings-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal___continue-button__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalDocsLinkClick = "settings-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__docs-link__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalTemplateLinkClick = "settings-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__template-link__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploaderModalBackButtonClicked = "settings-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__back-button__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploaderModalClosedOut = "settings-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__closed-out",
  SettingsBillingManagerPageBillingManagerModalCSVUploaderModalDocsLinkClick = "settings-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__docs-link__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploaderModalFileUploaded = "settings-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__file__uploaded",
  SettingsBillingManagerPageBillingManagerModalCSVUploaderModalTemplateLinkClick = "settings-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__template-link__clicked",
  SettingsBillingManagerPageBillingManagerModalCSVUploaderModalUploadButtonClick = "settings-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__upload-button__clicked",
  SettingsDeleteMultifactorButtonClick = "settings-flow__account-page__delete-multifactor-button__clicked",
  SettingsDeleteMultifactorFinish = "settings-flow__account-page__delete-multifactor__finish",
  SettingsInitiateMultifactorButtonClick = "settings-flow__account-page__initiate-multifactor-button__clicked",
  SettingsInitiateMultifactorFinish = "settings-flow__account-page__initiate-multifactor__finish",
  SettingsInviteMembersButtonClick = "settings-flow__members-page__invite-members-button__clicked",

  // Signin flow
  SignInNewAccountButtonClick = "signin-flow__signin-page__new-account-button__clicked",
  SignInPageForgotPasswordButtonClick = "signin-flow__signin-page__forgot-password-button__clicked",
  SignInPageLoginButtonClick = "signin-flow__signin-page__login-button__clicked",
  SignInVerifyRecoveryCodeModalContinueButtonClick = "signin-flow__verify-recovery-code-modal__continue-button__clicked",
  SignInVerifyRecoveryCodeModalSuccess = "signin-flow__verify-recovery-code-modal__success",
  SignInVerifyRecoveryCodeModalWrongCodeError = "signin-flow__verify-recovery-code-modal__wrong-code__error",
  SignInVerifyTOTPCodeCantAccessDeviceButtonClick = "signin-flow__verify-totp-code-modal__cant-access-device-button__clicked",
  SignInVerifyTOTPCodeModalContinueButtonClick = "signin-flow__verify-totp-code-modal__continue-button__clicked",
  SignInVerifyTOTPCodeModalSuccess = "signin-flow__verify-totp-code-modal__success",
  SignInVerifyTOTPCodeModalWrongCodeError = "signin-flow__verify-totp-code-modal__wrong-code__error",

  // Signup flow
  SignUpBusinessTypeSelectPageGoBackButtonClick = "signup-flow__business-type-select-page__back-button__clicked",
  SignUpCompanyDetailsPageContinueButtonClick = "signup-flow__company-details-page__continue-button__clicked",
  SignUpCompanyDetailsPageGoBackButtonClick = "signup-flow__company-details-page__back-button__clicked",
  SignUpCompanyInvestorSplitContinueButtonClick = "signup-flow__company-investor-split-page__continue-button__clicked",
  SignUpJoinInvestorWaitlistContinueButtonClick = "signup-flow__join-investor-waitlist-page__continue-button__clicked",
  SignUpJoinInvestorWaitlistGoBackButtonClick = "signup-flow__join-investor-waitlist-page__back-button__clicked",
  SignUpPersonalDetailsPageButtonClick = "signup-flow__personal-details-page__continue-button__clicked",
  SignUpTellUsAboutYouPagePageGoBackButtonClick = "signup-flow__tell-us-about-you-page__back-button__clicked",

  // Suggestion flow
  SuggestionAddAccountingDataModalAccountingDataSourceClick = "suggestion-flow__add-accounting-data-modal__accounting-data-source__clicked",
  SuggestionAddAccountingDataModalAccountingDetailsBackButtonClick = "suggestion-flow__add-accounting-data-modal__accounting-details-modal__back-button__clicked",
  SuggestionAddAccountingDataModalAccountingDetailsInstructionsButtonClick = "suggestion-flow__add-accounting-data-modal__accounting-details-modal__instructions-button__clicked",
  SuggestionAddAccountingDataModalAccountingDetailsModalClose = "suggestion-flow__add-accounting-data-modal__accounting-details-modal__closed-out",
  SuggestionAddAccountingDataModalAccountingDetailsSubmitButtonClick = "suggestion-flow__add-accounting-data-modal__accounting-details-modal__credentials-submit-button__clicked",
  SuggestionAddAccountingDataModalCSVUploaderModalBackButtonClicked = "suggestion-flow__add-accounting-data-modal__csv-uploader-modal__back-button__clicked",
  SuggestionAddAccountingDataModalCSVUploaderModalClosedOut = "suggestion-flow__add-accounting-data-modal__csv-uploader-modal__closed-out",
  SuggestionAddAccountingDataModalCSVUploaderModalFileUploaded = "suggestion-flow__add-accounting-data-modal__csv-uploader-modal__file__uploaded",
  SuggestionAddAccountingDataModalCSVUploaderModalTemplateLinkClick = "suggestion-flow__add-accounting-data-modal__csv-uploader-modal__template-link__clicked",
  SuggestionAddAccountingDataModalCSVUploaderModalUploadButtonClick = "suggestion-flow__add-accounting-data-modal__csv-uploader-modal__upload-button__clicked",
  SuggestionBillingManagerDetailsModalBackButtonClick = "suggestion-flow__billing-manager-details-modal__back-button__clicked",
  SuggestionBillingManagerDetailsModalClose = "suggestion-flow__billing-manager-details-modal__closed-out",
  SuggestionBillingManagerDetailsModalInstructionsButtonClick = "suggestion-flow__billing-manager-details-modal__instructions-button__clicked ",
  SuggestionBillingManagerDetailsModalSubmitButtonClick = "suggestion-flow__billing-manager-details-modal__submit-button__clicked",
  SuggestionBillingManagerInModalClick = "suggestion-flow__billing-manager-in-modal__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalBackButtonClicked = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__back-button__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalClosedOut = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__closed-out",
  SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalContactSupportLinkClick = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__contact-support-link__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalContinueButtonClick = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__continue-button__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalDocsLinkClick = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__docs-link__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalTemplateLinkClick = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__template-link__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalBackButtonClicked = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__back-button__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalClosedOut = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__closed-out",
  SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalDocsLinkClick = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__docs-link__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalFileUploaded = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__file__uploaded",
  SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalTemplateLinkClick = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__template-link__clicked",
  SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalUploadButtonClick = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__upload-button__clicked",

  // Trade Action Required flow
  TradeRequiredActionAddAccountingDataModalAccountingDataSourceClick = "trade-required-action-flow__add-accounting-data-modal__accounting-data-source__clicked",
  TradeRequiredActionAddAccountingDataModalAccountingDetailsBackButtonClick = "trade-required-action-flow__add-accounting-data-modal__accounting-details-modal__back-button__clicked",
  TradeRequiredActionAddAccountingDataModalAccountingDetailsInstructionsButtonClick = "trade-required-action-flow__add-accounting-data-modal__accounting-details-modal__instructions-button__clicked",
  TradeRequiredActionAddAccountingDataModalAccountingDetailsModalClose = "trade-required-action-flow__add-accounting-data-modal__accounting-details-modal__closed-out",
  TradeRequiredActionAddAccountingDataModalAccountingDetailsSubmitButtonClick = "trade-required-action-flow__add-accounting-data-modal__accounting-details-modal__credentials-submit-button__clicked",
  TradeRequiredActionAddAccountingDataModalCSVUploaderModalBackButtonClicked = "trade-required-action-flow__add-accounting-data-modal__csv-uploader-modal__back-button__clicked",
  TradeRequiredActionAddAccountingDataModalCSVUploaderModalClosedOut = "trade-required-action-flow__add-accounting-data-modal__csv-uploader-modal__closed-out",
  TradeRequiredActionAddAccountingDataModalCSVUploaderModalFileUploaded = "trade-required-action-flow__add-accounting-data-modal__csv-uploader-modal__file__uploaded",
  TradeRequiredActionAddAccountingDataModalCSVUploaderModalTemplateLinkClick = "trade-required-action-flow__add-accounting-data-modal__csv-uploader-modal__template-link__clicked",
  TradeRequiredActionAddAccountingDataModalCSVUploaderModalUploadButtonClick = "trade-required-action-flow__add-accounting-data-modal__csv-uploader-modal__upload-button__clicked",
  TradeRequiredActionBillingManagerDetailsModalBackButtonClick = "trade-required-action-flow__billing-manager-details-modal__back-button__clicked",
  TradeRequiredActionBillingManagerDetailsModalClose = "trade-required-action-flow__billing-manager-details-modal__closed-out",
  TradeRequiredActionBillingManagerDetailsModalInstructionsButtonClick = "trade-required-action-flow__billing-manager-details-modal__instructions-button__clicked ",
  TradeRequiredActionBillingManagerDetailsModalSubmitButtonClick = "trade-required-action-flow__billing-manager-details-modal__submit-button__clicked",
  TradeRequiredActionBillingManagerInModalClick = "trade-required-action-flow__billing-manager-in-modal__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalBackButtonClicked = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__back-button__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalClosedOut = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__closed-out",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalContactSupportLinkClick = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__contact-support-link__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalContinueButtonClick = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__continue-button__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalDocsLinkClick = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__docs-link__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalTemplateLinkClick = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__template-link__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalBackButtonClicked = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__back-button__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalClosedOut = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__closed-out",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalDocsLinkClick = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__docs-link__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalFileUploaded = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__file__uploaded",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalTemplateLinkClick = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__template-link__clicked",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalUploadButtonClick = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__upload-button__clicked",

  // Trading flow
  TradingOrderBoxChangeAdjustableTermsRadioClick = "trading-flow__order-box-change-adjustable-terms-radio__clicked",
  TradingOrderBoxViewAdjustableTermsBackArrowClick = "trading-flow__order-box-view-adjustable-terms-back-arrow__clicked",
  TradingOrderBoxViewAdjustableTermsClick = "trading-flow__order-box-view-adjustable-terms__clicked",
  TradingOrderSummaryPagePayoutButtonClick = "trading-flow__order-summary-page_payout-button__clicked",
  TradingPageReviewOrderButtonClick = "trading-flow__trade-page__review-order-button__clicked",
  TradingPageScheduleButtonClick = "trading-flow__trade-page__schedule-button__clicked",
  TradingPastTradesConnectWithFinanceTeamButtonClicked = "trading-flow__past-trades-page__connect-with-finance-team-button__clicked",
  TradingPastTradesPageTradeClick = "trading-flow__past-trades-page__trade__clicked",
  TradingTradePageSliderClick = "trading-flow__trade-page__slider__clicked",

  // Treasury
  TreasurySidePaneDepositButtonClicked = "treasury-side-pane__deposit-button__clicked",
  TreasurySidePaneDepositFromMenuClicked = "treasury-sidepane__deposit-from-menu__clicked",
  TreasurySidePaneDepositTabClicked = "treasury-side-pane__deposit-tab__clicked",
  TreasurySidePanePayoutToMenuClicked = "treasury-sidepane__payout-to-menu__clicked",
  TreasurySidePaneWithdrawalButtonClicked = "treasury-side-pane__withdrawal-button__clicked",
  TreasurySidePaneWithdrawalTabClicked = "treasury-side-pane__withdrawal-tab__clicked",
  TreasuryWaitlistButtonClicked = "treasury-waitlist__join-waitlist-button__clicked",
  TreasuryWaitlistCallUsClicked = "treasury-waitlist__call-us-button__clicked",
  TreasuryWaitlistLearnMoreButtonClicked = "treasury-waitlist__learn-more-button__clicked",
  TreasuryWaitlistOpenIntercomClicked = "treasury-waitlist__open-intercom-button__clicked",
  TreasuryWaitlistSendEmailClicked = "treasury-waitlist__send-email-button__clicked",
  TreasuryWaitlistViewHelpDocsClicked = "treasury-waitlist__view-help-docs-button__clicked",

  // Vendor
  VendorChangelogPopupClosed = "vendor-changelog__modal-popup__closed",
  VendorChangelogPopupOpened = "vendor-changelog__modal-popup__opened",
  VendorLegalEntityExperimentIncluded = "vendor-legal-entity-experiment__included",
  VendorLegalEntityFormInboxClosed = "vendor-legal-entity-form-inbox__closed",
  VendorLegalEntityFormInboxStepClicked = "vendor-legal-entity-form-inbox-step__clicked",
  VendorLegalEntityFormInboxSubmitted = "vendor-legal-entity-form-inbox__submitted",
  VendorSecondDataSourceConnected = "vendor-second-data-source__connected",
}

export const SegmentTrackPageGenerator = {
  MCListsImpression: (listName: string): string => `mc-lists__${listName}__impression`,
  NewListsImpression: (listName: string): string => `mc-new-lists__${listName}__impression`,
};

/**
 * ISegmentTrackPage describes the list of page/view/some UI element impressions, i.e.
 * things that are displayed to the user.
 *
 * The naming convention for the event names that's sent to Segment is a combination of:
 *  - Flow: where in the app flow this event is fired from e.g. 'trading-flow'
 *  - View: can be (a) an individual page (2) a modal (3) a list view etc.
 *  - Impression
 *
 * The formula for the final ISegmentTrackEvent name is: flow + view + impression.
 *
 * Decision log: Some of the user flows in our app contains pages that do not uniquely
 * correspond to the urls - hence the decision to use explicit names as page events vs
 * page urls.
 */
export enum ISegmentTrackPage {
  // Cash performance flow
  CashPerformancePlaidModalImpression = "cash-performance-flow__plaid-modal__impression",

  // Finance flow
  FinanceOverviewPageImpression = "finance-flow__overview-page__impression",
  FinancePaymentsListViewImpression = "finance-flow__payments-list-view__impression",
  FinanceStatementsPageImpression = "finance-flow__statements-page__impression",

  // Investor flow
  InvestorContractsPageImpression = "investor-flow__contracts-page__impression",
  InvestorExposurePageImpression = "investor-flow__exposure-page__impression",
  InvestorOrderDetailsPageImpression = "investor-flow__order-details-page__impression",
  InvestorOrdersPageImpression = "investor-flow__orders-page__impression",
  InvestorOverviewPageImpression = "investor-flow__overview-page__impression",
  InvestorSettingsAccountPageImpression = "investor-flow__settings-account-page__impression",
  InvestorSettingsMembersPageImpression = "investor-flow__settings-members-page__impression",
  InvestorTradingPageImpression = "investor-flow__trading-page__impression",
  InvestorTransferDetailsPageImpression = "investor-flow__transfer-details-page__impression",
  InvestorTransfersPageImpression = "investor-flow__transfers-page__impression",

  // Mission Control
  MCDemosPageImpression = "mc-demos__page__impression",
  MCFilesPageImpression = "mc-files__page__impression",
  MCFinanceDashboardImpression = "mc-finance__dashboard__impression",
  MCHeaderCommandKImpression = "mc-header__command-k__impression",
  MCHomeDashboardImpression = "mc-home__dashboard__impression",
  MCMyListsCreateImpression = "mc-my-lists__create__impression",
  MCMyListsIndexImpression = "mc-my-lists__index__impression",
  MCNewListsIndexImpression = "mc-new-lists__index__impression",
  MCRatingsDashboardImpression = "mc-ratings__dashboard__impression",
  MCSalesDashboardImpression = "mc-sales__dashboard__impression",
  MCVendorActivityLogPageImpression = "mc-vendor-activity-log__page__impression",
  MCVendorDataSourcesManualManageImpression = "mc-vendor-data-sources__manual-manage__impression",
  MCVendorDataSourcesPageImpression = "mc-vendor-data-sources__page__impression",
  MCVendorMessageFeedPageImpression = "mc-vendor-message-feed__page__impression",
  MCVendorPaymentsIndividualImpression = "mc-vendor-payments__individual__impression",
  MCVendorPaymentsListImpression = "mc-vendor-payments__list__impression",
  MCVendorRevenueStreamsPageImpression = "mc-vendor-revenue-streams__page__impression",
  MCVendorSettingsPageImpression = "mc-vendor-settings__page__impression",
  MCVendorSummaryPageImpression = "mc-vendor-summary__page__impression",
  MCVendorUsersPageImpression = "mc-vendor-users__page__impression",

  // Settings flow
  SettingsAccountPageImpression = "settings-flow__account-page__impression",
  SettingsAddAccountingDataModalAccountingDetailsModalImpression = "settings-flow__add-accounting-data-modal__accounting-details-modal__impression",
  SettingsAddAccountingDataModalCSVUploaderModalImpression = "settings-flow__add-accounting-data-modal__csv-uploader-modal__impression",
  SettingsAddAccountingDataModalImpression = "settings-flow__add-accounting-data-modal__impression",
  SettingsAutomaticManageCSVModalImpression = "settings-flow__automatic-manage-csv-modal__impression",
  SettingsBillingManagerDetailsModalImpression = "settings-flow__billing-manager-details-modal__impression",
  SettingsBillingManagerModalImpression = "settings-flow__billing-manager-modal__impression",
  SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalImpression = "settings-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__impression",
  SettingsBillingManagerPageBillingManagerModalCSVUploaderModalImpression = "settings-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__impression",
  SettingsCompanyPageImpression = "settings-flow__company-page__impression",
  SettingsConnectAPIPageImpression = "settings-flow__connect-api-page__impression",
  SettingsIntegrationsPageImpression = "settings-flow__integrations-page__impression",
  SettingsMembersPageImpression = "settings-flow__members-page__impression",
  SettingsPlaidModalImpression = "settings-flow__plaid-modal__impression",

  // Signin flow
  SignInPageImpression = "signin-flow__signin-page__impression",
  SignInVerifyRecoveryCodeModalImpression = "signin-flow__verify-recovery-code-modal__impression",
  SignInVerifyTOTPCodeModalImpression = "signin-flow__verify-totp-code-modal__impression",

  // Signup flow
  SignUpBusinessTypeSelectPageImpression = "signup-flow__business-type-select-page__impression",
  SignUpCompanyDetailsPageImpression = "signup-flow__company-details-page__impression",
  SignUpCompanyInvestorSplitPageImpression = "signup-flow__company-investor-split-page__impression",
  SignUpEmailVerificationPageImpression = "signup-flow__email-verification-sent-page__impression",
  SignUpEntertainmentWaitlistPageImpression = "signup-flow__entertainment-waitlist-page__impression",
  SignUpInvestorWaitlistConfirmationPageImpression = "signup-flow__investor-waitlist-confirmation-page__impression",
  SignUpJoinInvestorWaitlistPageImpression = "signup-flow__join-investor-waitlist-page__impression",
  SignUpTellUsAboutYouPageImpression = "signup-flow__tell-us-about-you-page__impression",

  // Suggestion flow
  SuggestionAddAccountingDataModalAccountingDetailsModalImpression = "suggestion-flow__add-accounting-data-modal__accounting-details-modal__impression",
  SuggestionAddAccountingDataModalCSVUploaderModalImpression = "suggestion-flow__add-accounting-data-modal__csv-uploader-modal__impression",
  SuggestionAddAccountingDataModalImpression = "suggestion-flow_add-accounting-data-modal_impression",
  SuggestionBillingManagerDetailsModalImpression = "suggestion-flow__billing-manager-details-modal__impression",
  SuggestionBillingManagerModalImpression = "suggestion-flow__billing-manager-modal__impression",
  SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalImpression = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__impression",
  SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalImpression = "suggestion-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__impression",

  // Trade Action Required flow
  TradeRequiredActionAddAccountingDataModalAccountingDetailsModalImpression = "trade-required-action-flow__add-accounting-data-modal__accounting-details-modal__impression",
  TradeRequiredActionAddAccountingDataModalCSVUploaderModalImpression = "trade-required-action-flow__add-accounting-data-modal__csv-uploader-modal__impression",
  TradeRequiredActionAddAccountingDataModalImpression = "trade-required-action-flow_add-accounting-data-modal_impression",
  TradeRequiredActionBillingManagerDetailsModalImpression = "trade-required-action-flow__billing-manager-details-modal__impression",
  TradeRequiredActionBillingManagerModalImpression = "trade-required-action-flow__billing-manager-modal__impression",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalImpression = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-upload-educational-modal__impression",
  TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalImpression = "trade-required-action-flow__billing-manager-page__billing-manager-modal__csv-uploader-modal__impression",
  TradeRequiredActionPlaidModalImpression = "trade-required-action-flow__plaid-modal__impression",

  // Trading flow
  TradingOrderConfirmationImpression = "trading-flow__order-confirmation__impression",
  TradingOrderSummaryPageImpression = "trading-flow__order-summary-page__impression",
  TradingPastTradeDetailsImpression = "trading-flow__past-trade-details-page__impression",
  TradingPastTradesPageImpression = "trading-flow__past-trades-page__impression",
  TradingTradeListViewImpression = "trading-flow__trade-list-view__impression",
  TradingTradePageImpression = "trading-flow__trade-page__impression",
}

/**
 * The track API call is how you record any actions users perform, along with any properties that describe the action.
 * More info: https://segment.com/docs/connections/spec/track/
 * @param event The name of the event (defined in Segment dashboard) you’re tracking.
 * @param properties Metadata to pass to Segment (e.g. user_id, vendor_id, etc.)
 * @param options Options to pass to Segment.
 * @param callback A function that is executed after a short timeout, giving the browser time to make outbound requests first.
 */
const segmentTrackEvent = (
  event: string,
  properties?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void,
): void => {
  ConsoleLog("segmentTrackEvent", event, properties);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.analytics?.track(
    event,
    {
      ...properties,
      // Backend can also issue Segment events
      source: "frontend",
    },
    options,
    callback,
  );
};

const segmentTrackPage = (page: string, properties?: Record<string, unknown>): void => {
  ConsoleLog("segmentTrackPage", page, properties);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.analytics?.page(page, {
    ...properties,
    // Backend can also issue Segment events
    source: "frontend",
  });
};

/**
 * useUserSegmentInfo is a hook that can be used to get the common user fields
 * for an authenticated user to track in segment.
 */
const useUserSegmentInfo = (): Record<string, unknown> => {
  const user = useMaybeUser();
  const userTrackInfo: Record<string, unknown> = useMemo(() => {
    let _userTrackInfo: Record<string, unknown> = {};
    if (user !== null) {
      _userTrackInfo = {
        user_public_id: user.publicID,
        vendor_public_id: user.vendor.id,
      };
    }
    return _userTrackInfo;
  }, [user]);
  return userTrackInfo;
};

type IUseAnalytics = {
  trackEvent: typeof segmentTrackEvent;
  trackPage: typeof segmentTrackPage;
};

/**
 * useAnalytics hook exposes trackEvent & trackPage functions to issue events
 * to Segment from the app.
 * @returns
 *    trackEvent: to be used for user-initiated actions
 *    trackPage: to be used for page/view impressions
 */
export const useAnalytics = (): IUseAnalytics => {
  // Common user fields to attach to all segment events
  // when user is authenticated.
  const commonUserTrackInfo = useUserSegmentInfo();

  // Create stable ref so the trackEvent and trackPage callbacks are static and never change
  // (so we don't double count if trackEvent is in a dep array). More idiomatic React
  const commonUserTrackInfoRef = useRef(commonUserTrackInfo);
  useEffect(() => {
    commonUserTrackInfoRef.current = commonUserTrackInfo;
  }, [commonUserTrackInfo]);

  const trackEvent = useCallback(
    (
      event: ISegmentTrackEvent,
      properties?: Record<string, unknown>,
      options?: SegmentAnalytics.SegmentOpts,
      callback?: () => void,
    ) =>
      segmentTrackEvent(
        event,
        {
          ...properties,
          ...commonUserTrackInfoRef.current,
        },
        options,
        callback,
      ),
    [],
  );

  const trackPage = useCallback((page: ISegmentTrackPage, properties?: Record<string, unknown>) => {
    segmentTrackPage(page, {
      ...properties,
      ...commonUserTrackInfoRef.current,
    });
  }, []);

  const trackingObj = useMemo(() => {
    return {
      trackEvent,
      trackPage,
    };
  }, [trackEvent, trackPage]);

  return trackingObj;
};
